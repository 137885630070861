<template>
    <el-dropdown class="user-name" trigger="click" :title="$t('components.account')">
        <span class="el-dropdown-link">
            <span class="icon nav-icons">
                <svg-icon :title="$t('components.account')" icon-class="user-solid-circle" />
            </span>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
                <span style="font-weight: bold;">{{ userName }}</span>
            </el-dropdown-item>
            <br />
            <el-dropdown-item @click.native="logout"> <svg-icon icon-class="logout" />&nbsp;Log out </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            userData: {
                name: ''
            }
        };
    },
    computed: {},
    methods: {
        async logout() {
            try {
                await this.$store.dispatch('user/LogOut', {
                    withRequest: true
                });
                this.$router.push('/login').catch(() => {});
            } catch (err) {}
        }
    },
    computed: {
        ...mapGetters({
            currentLang: 'app/currentLang',
            userName: 'user/userName'
        })
    },
    async created() {}
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-dropdown {
    color: #9f9f9f;
    fill: #9f9f9f;
    display: block;
    font-size: 27px;

    .svg-icon {
        display: block;
    }
}
</style>
